import "./Schedule.css";

const SchemaItem = ({ time, icon, text, link }) => {
  return <div className="schema-item">
    <img src={icon} alt="Icon"/>
    <span className="schema-time">{time}</span>
    <span className="schema-title">{text}</span>
  </div>
}

const Page = () => {
  return (
    <>
      <h2>Schema</h2>
      <div className="schedule">
        <div>
          <h3>Fredag</h3>
          <SchemaItem time="15:00" icon="/icons/Key.png" text="Check-in på havshotellet" />
          <SchemaItem time="19:30" icon="/icons/Food.png" text="Middag" />
        </div>
        <div>
          <h3>Lördag</h3>
          <SchemaItem time="12:00" icon="/icons/Lunch.png" text="Optional: Lunch" />
          <SchemaItem time="13:20" icon="/icons/Boat.png" text="Färja till Marstrand" />
          <SchemaItem time="14:00" icon="/icons/Marriage.png" text="Vigsel" />
          <SchemaItem time="16:30" icon="/icons/Champagne.png" text="Mingel, tilltugg & Brudskål" />
          <SchemaItem time="18:45" icon="/icons/Dinner.png" text="Middag" />
          <SchemaItem time="01:55" icon="/icons/Dance.png" text="Sista dansen" />
        </div>
        <div>
          <h3>Söndag</h3>
          {/* <SchemaItem time="-11.00" icon="/icons/Spa.png" text="Spa Access für alles" /> */}
          <SchemaItem time="XX.00" icon="/icons/Breakfast.png" text="Frukost på Havshotellet" />
          <SchemaItem time="XX.00" icon="/icons/Mystery.png" text="To be decided" />
          <SchemaItem time="12.00" icon="/icons/Key.png" text="Check-out Havshotellet" />
        </div>
      </div>
    </>
  );
};

export default Page;
