import "./App.css";
import Menu from "./components/Menu";
import Main from "./pages/Main";
import Boende from "./pages/Boende";
import Kontakt from "./pages/Kontakt";
import Schedule from "./pages/Schedule";
import GoodToKnow from "./pages/GoodToKnow";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import { useEffect, useRef } from 'react';

const routes = [
  {
    title: "Hem",
    path: "/",
    element: <Main />,
  },
  {
    title: "Information & Länkar",
    path: "/information",
    element: <GoodToKnow />,
  },
  {
    title: "Boende",
    path: "/boende",
    element: <Boende />,
  },
  {
    title: "Schema",
    path: "/schema",
    element: <Schedule />,
  },
  {
    title: "Kontakt",
    path: "/kontakt",
    element: <Kontakt />,
  }
];

function App() {
  return (
    <div className="App">
      <APIProvider
        apiKey="AIzaSyBB8WQRzI_6Dsncd-E2tp8NUnQI08fOysc"
        onLoad={() => console.log("Maps API has loaded. now")}
      >
        <BrowserRouter>
          <ScrollToAnchor />
          <header className="App-header">
            <Menu routes={routes} type="mobile" />
          </header>
          <main>
            <h1>Christian & Johanna</h1>
            <Menu routes={routes} type="desktop" />
            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
            </Routes>
          </main>
          <footer>
            <span>Marstrand 8/3 2025</span>
          </footer>
        </BrowserRouter>
      </APIProvider>
    </div>
  );
}

export default App;


function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
}
