import "./GoodToKnow.css";

export const Page = () => {
  return (
    <div className="good-to-know">
      <h2>Information och Länkar</h2>
      <p>
        Här kommer vi att lägga upp information som kan vara bra att ha koll på
        inför bröllopet.
      </p>
      <div>
        <h3 id="kladkod">Klädkod</h3>
        <p>
          För fredagens mingel och middag är ingen skarp klädkod, utan så fin
          man vill och hinner klä sig.
        </p>
        <p>
          Klädkoden för lördagen är mörk kostym. <br />
          Det som kan vara värt att tänka på för både herrar och damer är att
          början på dagen (innan middagen) kommer att spenderas ute på ön
          Marstrand som har kullerstensgator och ibland har något nyckfullt
          väder. Vi rekommenderar varmt att man väljer skor (och jacka) som är
          anpassade för underlag och temperatur, det finns chans att byta skor
          innan middagen.
        </p>
        <p>
          Obs. Ni som inte bor över på havshotellet, hojta till om ni skulle
          vilja tillfälligt parkera skor och ombyten där så kan vi visa er till
          ett bra rum för tillfällig förvaring av dessa.
        </p>
        <p>
          För herrar:
          <br />
          Mörk kostym en hel kostym i mörkare tyg med slips eller fluga.
        </p>
        <p>
          För damer:
          <br />
          Som alltid är klädkoder för damer en fråga av relativitet, men i regel
          brukar mörk kostym-klänningar/kjolar vara minst nedanför knäna till
          hellång. Lite löst kan man säga snäppet finare än cocktail och snäppet
          mindre fin än balklänning. Söndag är helt fri klädkod, utom i badet,
          där bör man ha minst badkläder, för den goda smakens skull.
        </p>
      </div>
      <div className="checklist">
        <h3 id="packlista">Packlista</h3>
        <p>
          Vi vet att ni alla kan packa en väska själva, men för alla som gillar
          att dubbelkolla inför en resa så är det här vad vi själva kan komma på
          att man skulle behöva för en helg på västkusten.
        </p>
        <ul>
          <li>Festlighetskläder för lördag</li>
          <li>
            Finskor Skor för utomhusbruk (som fixar kullersten och eventuellt
            regn)
          </li>
          <li>Badkläder</li>
          <li>
            Regntåliga ytterkläder och/eller robust paraply (som känt regnar det
            sällan utan vind på västkusten)
          </li>
          <li>
            Om man skulle vilja utforska Marstrandsön: oömtåliga friluftsskor
            och kläder
          </li>
          <li>Resorb och smärtlindrande, kanske inte dumt</li>
        </ul>
      </div>
      <div>
        <h3 id="gavor">Gåvor</h3>
        <p>Bästa vänner och familj!</p>
        <p>
          Som vi hoppas att ni vet, så är det viktigaste för oss att få spendera
          vår dag med er och ha roligt en helg. Vi är enormt tacksamma för att
          ni sätter av en helg och i många fall reser långt för att kunna vara
          med.
        </p>
        <p>
          Om man ändå skulle vilja ge en bröllopsgåva så blir vi så klart
          väldigt glada och rörda även för det i vilken form det än skulle vara,
          från en traditionell gåva i fysisk form till en aktivitet vi gör ihop.
        </p>
      </div>
      <div>
        <h3 id="jag-vill-halla-tal">Jag vill hålla tal!</h3>
        <p>
          Om det skulle vara så att man vill hålla tal, publikt anklaga oss för
          något, sjunga lite eller få en grupp välklädda människor att genomföra
          synkroniserad macarena så kommer vi inte stoppa er, men det kanske
          vårt fenomenala toastpar gör. Läs mer om vårt toastpar här.
        </p>
        <p>
          De kommer styra med järnhand och illvilja från va 18.45 på lördag
          tills dess att sista biten mat är tuggad och svald och vi rest oss
          från våra stolar.
        </p>
        <p>
          Kontakta dem för att anmäla ditt tal, spex eller dylikt på följande
          mailadress:<br />
          <a href="mailto:toastmasters@johannachristian.se">toastmasters@johannachristian.se</a>
        </p>
        <p>
          Det kan vara god sed att informera om vem du är, och vad du har för
          anspråk i form av talartid eller rekvisita.
        </p>
      </div>
      <div>
        <h3 id="bra-att-ha-lankar">Bra att ha länkar</h3>
        <p>
          Google Maps-lista med platser för kyrkan, hotellet, parkering,
          restaurang för fredag:
          <br />
          <a href="https://maps.app.goo.gl/pzpnPv4NMijivb3j9">
            https://maps.app.goo.gl/pzpnPv4NMijivb3j9
          </a>
        </p>
        <p>
          Västtrafik
          <br />
          <a href="https://www.vasttrafik.se/">https://www.vasttrafik.se/</a>
        </p>

        <p>
          Tidtabell för busslinje 302 (som går från Kungälv till Marstrand)
          <br />
          <a href="https://www.vasttrafik.se/reseplanering/tidtabeller/linje/9011014630200000/">
           Buss 302 
          </a>
        </p>
        <p>
          Hållplatser som är bra att veta om är:
          <ul>
            <li>
              Marstrands färjeläge, Kungälv
              <ul>
                <li>Här ligger havshotellet</li>
              </ul>
            </li>
            <li>
              Kungälv resecentrum, Kungälv
              <ul>
                <li>Resehubben i metropolen Kungälv</li>
              </ul>
            </li>
            <li>
              Kongahällagatan, Kungälv
              <ul>
                <li>Ligger närmast restaurangen Westra porten</li>
              </ul>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Page;
