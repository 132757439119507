import React from "react";
import { FaMailBulk, FaPhoneAlt } from "react-icons/fa";
import "./Kontakt.css";

const Person = ({ name, image, phone, email, description }) => {
  if (!image) {
    image = "/avatar/placeholder.jpg";
  }
  return (
    <div className="contact-card-container">
      <div className="contact-card">
        <img src={image} alt={name} className="contact-image" />
        <div>
          <h3>{name}</h3>
          {phone && (
            <span>
              <FaPhoneAlt className="contact-icon" />
              <a href={`sms:${phone.replace(/\s/g, "")}`}>{phone}</a>
            </span>
          )}
          {email && (
            <span>
              <FaMailBulk className="contact-icon" />
              <a href={`mailto:${email}`}>Email</a>
            </span>
          )}
        </div>
      </div>
      {description && <p>{description}</p>}
    </div>
  );
};

const Page = () => {
  return (
    <div className="contact-page">
      <h2>Kontakt</h2>
      <h3>Toastpar</h3>
      <p>
        Till vår stora glädje och förskräckelse börjar vi med att presentera
        våra omåttligt charmiga, roliga och luriga toastpar.
      </p>
      <div className="contact-cards">
        <Person
          name={"Sofia Tollander"}
          email={"toastmasters@johannachristian.se"}
          image={"/avatar/Sofia.jpg"}
          description={
            <>
              <p>
                Sedan gammelt så damerna först!
                <br />
                Sofia och Johanna träffades i ett gemensamt konsult-elddop på en
                viss batterifabrik, och hittade tröst, hopp och vänskap i mörk
                humor, powerpoint och Gin & Tonic.{" "}
              </p>
              <p>
                Sofia är en briljant kvinna med oändligt mycket energi som kan
                få dig tro att du orkar göra vad som helst, även om du är halvt
                medvetslös. Norrländsk urkraft möter knivskarp humor som nu för
                tiden residerar i Tysklands friluftsort München.
              </p>
            </>
          }
        />
        <Person
          name={"Ivan Milles"}
          email={"toastmasters@johannachristian.se"}
          image={"/avatar/Ivan.jpg"}
          description={
            <>
              <p>
                I forntiden, för att skapa osämja, brukade termen kollega
                användas för att beskriva relationen till Ivan. Nu för tiden är
                Ivan en självklarhet i vardagen, och vår tillvaro skulle inte
                vara densamma utan honom. Han har blivit en extra familjemedlem
                mot sin vilja, och han kan inte bli fri, det finns för mycket
                bilder och hemligheter begravda i den proverbiala mossen.
              </p>
              <p>
                Ivan är vår galna konstnärssjäl som egentligen bara vill bo på
                ett tåg och se varenda obekvämliga hörn av världen, men som
                också råkar vara en helt hysteriskt smart utvecklare, så han
                måste bo där tekniken bor. Till vår stora glädje och Ivans stora
                sorg.
              </p>
            </>
          }
        />
      </div>
      <h3>Västkustgeneral:</h3>
      <div className="contact-cards">
        <Person
          name={"Mamma Eva"}
          image={"/avatar/Eva.jpg"}
          phone={"070 575 96 29"}
          description={
            <>
              <p>
                Även känd som Johannas mamma Eva sedan 2004 (Full Cred till
                Pontus). Eva är utnämnd lokal guide inför det här äventyret och
                har lovat att vara en hjälpande hand till vilsna själar i allt
                som rör Kungälv och Marstrand eller expertkunskap om Johanna om
                det skulle behövas.
              </p>
            </>
          }
        />
      </div>
      <h3>Värdpar:</h3>
      <div className="contact-cards">
        <Person name={"Joakim von Feilitzen"} image={"/avatar/Joakim.jpg"}/>
        <Person name={"Nora von Feilitzen"} image={"/avatar/Nora.jpg"} />
      </div>
      <p>
        Enligt tradition är brudens föräldrar värdpar, och är de som välkomnar
        till sin hemort, till bröllopet och stöttar gästerna på plats under
        bröllopet. Som trygga lokala experter som kommer hjälpa er hitta till
        färjelägen och champagneflaskor har vi Johannas storebror och svägerska.
        Förutom att vara underbara och viktiga personer i våra liv så är de
        också experter på hur man uttalar det knepiga tyska efternamnet som
        behöver hojtas ut lite då och då!
      </p>
      <h3>Brudfölje:</h3>
      <div className="contact-cards">
        <Person
          name={"Ylva von Feilitzen"}
          image={"/avatar/Ylva.jpg"}
          description={
            <p>
              I vissa grupper känd som Uggla i Nalle Puh, i andra som Sensei.
              Ylva är en overkligt vis, rättvis, varm och närvarande person som
              har blivit bikthubben i många av vännernas liv. För Johanna är hon
              både bikthubb, kusin, bollplank, källa av visdom och kärlek. Det
              hade varit omöjligt och overkligt att stå vid ett altare utan att
              ha henne däruppe med mig.
            </p>
          }
        />
        <Person
          name={"Emelie Norberg"}
          image={"/avatar/Emelie.jpg"}
          description={
            <p>
              Johannas eviga partner in crime, den Norrländska Norbergskan som
              är yrvädret i vårat liv som alltid har tusen järn i elden, skapar
              designmagi och är har ett overkligt business sense i allting hon
              gör. Genom dimman av projekt så är hon vildsint lojal och generös
              mot sina vänner i vått och torrt, där både Johanna och Christian
              är enormt lyckliga över att få ha henne som en del av vår dag.
            </p>
          }
        />
      </div>
      <div className="contact-cards">
        <Person
          name={"Oskar Ottosson"}
          image={"/avatar/Oskar.jpg"}
          description={
            <p>
              Oskar och Chirstian har känt varandra sedan skolas tidiga dagar.
              Oskar är en av de mest pålitliga och lojala vänner speciellt ute i
              skogen och på fjället. Han är en av de få som kan få det att låta
              som en utmärkt ide att ta en tältövernattning i mörkaste november,
              bara för att det var länge sen sist.
            </p>
          }
        />
        <Person
          name={"Ivan Milles"}
          image={"/avatar/Milles.jpg"}
          description={
            <p>
              Att få nämnas twice är en självklarhet i fallet Milles. Den som
              stoltast deklarerat att han var den första supportern i “team C”
              sedan teorin om en möjlig relation uppstod. För mer superlativ om
              hans briljans, se rubriken “toastmaster - Ivan Milles”.
            </p>
          }
        />
      </div>
      <div className="contact-cards">
        <Person
          name={"Johanna"}
          phone={"072 193 50 05"}
          image={"/avatar/Johanna.jpg"}
        />
        <Person
          name={"Christian"}
          phone={"070 753 46 49"}
          image={"/avatar/Christian.jpg"}
        />
      </div>
    </div>
  );
};

export default Page;
