import "./Boende.css";

const Page = () => {
  return (
    <div className="hotel">
      <h2>Boende</h2>
      <img
        src="https://lh3.googleusercontent.com/p/AF1QipN8oBiPyY2tLC8XF-4X9iuIvH3-H-_1b8iN2xuG=s680-w680-h510"
        alt="Marstrand"
        className="img"
      />
      <p>
        Då festen efter bröllopet kommer att hållas på Marstrands Havshotell
        rekommenderar vi att ni bokar rum där. Hotellet har reserverat rum för
        våra gäster. Vi har fått en bokningskod som ni använder när ni bokar:{" "}
        <strong>johanna&christian25</strong>. I övrigt bokar ni som vanligt via
        deras hemsida:{" "}
        <a href="https://www.marstrands.se/">www.marstrands.se</a>
      </p>
      <p>
        Det finns även andra boenden på Marstrand, såsom vandrarhem och Bed &
        Breakfast. Om ni föredrar att bo på annat ställe är ni självklart
        välkomna att göra det.
      </p>
      <p>
        För lite större sällskap finns såklart även möjlighet att hyra hus eller
        stuga via Airbnb eller liknande.
      </p>
      <p>Att tänka på vid bokning av boende:</p>
      <ul>
        <li>
          Boka i god tid då det kan bli fullt på hotellet och vi har ingen
          garanti för att alla gäster kan få ett rum.
        </li>
        <li>
          Om ni bokar eget boende tänk på att boka det på "rätt" sida av sundet.
          Hotellet där festen hålls ligger på Koön.
        </li>
      </ul>
    </div>
  );
};

export default Page;
