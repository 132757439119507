import { Link } from "react-router-dom";
import "./Main.css";

const Page = () => {
  return (
    <div className="mainPage">
      <div>
    <h1>Herregud, det är mindre än en vecka kvar!</h1>
    <p>Vi håller lugnet genom att turas om att ha panikattacker och hunden har börjat tappa päls då han agerar emotionellt stöddjur (vilken krigare). Men framförallt är vi så <strong>EXTREMT taggade</strong> över att snart få träffa er alla.</p>

    <h2>Resan till Bohuslän</h2>
    <p>För er med bil rekommenderar vi varmt E4 väg 40 till Göteborg, därefter E6 till Kungälv och sedan marstrandsvägen väg 168 hela vägen till Koön.</p>

    <p>För er som tar tåg eller buss till Göteborg så finns det några alternativ för att komma till Koön:</p>
    <ul>
        <li>Buss till Kungälv resecentrum och sedan byta till buss 302 mot Marstrand</li>
        <li>Tåg till Ytterby och sedan byta till 302 mot Marstrand</li>
    </ul>
    <p>Vi rekommenderar inte taxi från Göteborg till Marstrand, då taxipriserna är mycket högre på västkusten och bilarna färre. Men det är så klart fritt val!</p>

    <h2>Parkeringsinformation</h2>
    <p>Det finns parkeringar direkt utanför hotellet. Om ni står på de platser som är märkta "Havshotellet" så behöver ni betala för ett parkeringskort i receptionen och lägga det i bilen. Det finns även kommunparkeringar runt om på Koön.</p>

    <h2>Incheckning, Middag och Transport - FREDAG</h2>
    <p>För de av er som kommer på <strong>FREDAG</strong>!</p>
    <p>Incheckningen är från 15:00 på Havshotellet. Därefter är det ju fri hopp och lek tills vi ses för middagen!</p>
    <p>Vi sätter oss tillbords 19:30.</p>
    <p>Middagen är inne i Kungälv, och dit kan man antingen ta sig med bil eller buss 302. Restaurangen ligger på västra gatan i Kungälv. Klicka på länken nedan för vägbeskrivning för både buss och bil:</p>
    <a href="https://maps.app.goo.gl/2WYn4xey7cirowJ9A">Vägbeskrivning till restaurangen</a>
    <p>Det tar ca 30 minuter med både buss och bil. Parkering för middagen kan man hitta här:</p>
    <a href="https://maps.app.goo.gl/zEqjxgS1Mx7feoNs9">Parkering vid restaurangen</a>
    <p>Vi kommer att hjälpas åt med att få alla hem från restaurangen med bil och buss!</p>

    <h2>Incheckning, Ombyten och Bagage - LÖRDAG</h2>
    <p>Flera av er kommer ner på <strong>LÖRDAG</strong> morgon. Vi ber er kolla med hotellet kring er incheckningstid så att ni vet om nedan gäller er.</p>
    <p>Om ni bor på Havshotellet men inte har tidig incheckning så kommer ni inte hinna checka in innan vigseln. Vi har kollat upp vad som gäller:</p>
    <ul>
        <li>Det finns rymliga omklädesrum i spa:t/gymmet på hotellet, vi har fri tillgång till dessa för ombyte och piff om man skulle vilja.</li>
        <li>Det finns bagagerum för eventuella väskor.</li>
        <li>Om man har med sig en bröllopsgåva rekommenderar vi att ni behåller dessa på hotellet tills vi är tillbaka från vigseln, för att slippa bära runt i onödan.</li>
        <li>Det kommer finnas tid till att checka in när vi är tillbaka från vigseln, mellan brudskålen och lite tilltugg (ca.16:30-17:10).</li>
    </ul>

    <h2>Information om Söndag</h2>
    <p>På <strong>söndag</strong> siktar vi på lugnt mys och spa!</p>
    <p>För er som skulle vilja äta frukost med brudparet kommer vi att sikta på att vara på frukosten runt 9:00-9:30 på söndagen.</p>
    <p>Alla gäster, oberoende av vad man har sagt i sin bokning, har tillgång till spa fram till kl. 11 på söndagen. Därefter kommer vi att sikta på mingel och lugnt häng på hotellet. För de som vill så har vi kollat upp en onlineguide för en rundtur på Marstrandsön (beroende på väder så klart).</p>

    <p>Stäm av utcheckning med hotellet efter önskemål, och glöm inte att krama oss innan ni flyr från kusten!</p>

    <h2>Frågor?</h2>
    <p>Om ni har några frågor, orosmoln eller generella känslor ni vill dela med er av, tveka inte att kontakta oss eller våra härliga trofasta vänner i räddningspatrollen. All info hittar ni här:</p>
    <Link to="/kontakt">Kontaktinformation</Link>
      </div>
    </div>
  );
};

export default Page;
